import axios from "axios";
import {  AUTH_SERVER_URL } from "constants/api.constant";
import { PERSIST_STORE_NAME } from "constants/app.constant";
import deepParseJson from "utils/deepParseJson";
const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
const persistData = deepParseJson(rawPersistData);
const accessToken = persistData?.auth?.session?.token;

const authApi = axios.create({
    baseURL: `${AUTH_SERVER_URL}`,
});

// Add a request interceptor
authApi.interceptors.request.use(
    async function (config) {
        const token = accessToken;
        config.headers = {
            "Access-Control-Allow-Origin": "*",
        };
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
            config.headers.Accept = `application/json`;
        }
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
authApi.interceptors.response.use(
    async function (response) {
        // Do something with response data
        const result = response.data;
        return result;
    },
    function (error) {
        // Do something with response error
        return Promise.reject(error);
    }
);

export default authApi;
