import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import {
  onSignInSuccess,
  // onSignInSuccess,
  onSignOutSuccess,
  setPermissions,
} from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { useLocation, useNavigate } from "react-router-dom";
import { login } from "service/authService";

// import { login } from "service/authService";

function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { token, signedIn } = useSelector((state) => state.auth.session);
  const entryPath = localStorage.getItem("entryPath");
  const signIn = async (data) => {
    try {
      const resp = await login(data);
      console.log("res", resp)
      if (resp?.success) {
        const token = resp?.data?.token;


        dispatch(onSignInSuccess(token));
        // dispatch(setUser(resp?.data));
        dispatch(
          setUser({
            id: resp?.data.id ? resp?.data.id : "",
            username: resp?.data.name ? resp?.data.name : "user",
            email: resp?.data.email ? resp?.data.email : "",
            role: resp?.data?.role ? resp?.data?.role : "",
            authority: resp?.data?.role
              ? resp?.data.role.split(" ")
              : [""],
            token: resp?.data?.token ? resp?.data?.token : "",
          })
        );


        navigate(entryPath ? entryPath : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
      dispatch(onSignInSuccess(token));
      navigate(entryPath ? entryPath : appConfig.authenticatedEntryPath);
      return {
        status: "success",
        message: "",
      };
    } catch (err) {
      console.log(err);
      return {
        status: "failed",
        message:
          err?.response?.data?.error?.message ||
          err?.response?.data?.error ||
          err?.response?.data?.message ||
          err.toString(),
      };
    }
  };

  const handleSignOut = () => {
    localStorage.setItem("entryPath", location.pathname);
    dispatch(setPermissions());
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    handleSignOut();
  };

  return {
    authenticated: token && signedIn,
    // authenticated: true,
    signIn,
    signOut,
  };
}

export default useAuth;
