import authApi from "./authApi";

export function login(data) {
  return authApi.post("/admin/login", data);
}

export function apiSignUp(data) {
  return authApi.post("/admin/register", data);
}



export function apiForgotPassword(data) {
  return authApi.post(`admin/forgot-password`, data);
}

export function apiResetPassword(token, data) {
  return authApi.put(`/admin/password/${token}`, data);
}
// export function verifyOTP(data) {
//   return api.post(`/auth/verify-otp`, data);
// }

// export function login(data) {
//   return authApi.post("/admin/login", data);
// }
